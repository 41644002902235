import axios from "axios";
import styled, { keyframes } from "styled-components";
import { useForm } from "react-hook-form";
import { debounce, property } from "lodash";
import { useQuery } from "react-query";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { ImSpinner9 } from "react-icons/im";
// import bgImage from "./images/bg.jpg";

const rotateSpinner = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  position: relative;
  padding: 100px 0;
  width: 100%;
  max-width: 520px;

  & input {
    box-shadow: none !important;
  }
`;

const Title = styled.h2`
  font-size: 3.2rem;
  line-height: 1.3;
  font-weight: 700;
  text-align: center;
`;
const ConformationMessage = styled.h2`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: 700;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  width: 100%;
  margin: 0 auto;

  & > * {
    margin: 0.5em 0;
  }
`;

const Input = styled.input`
  color: black !important;
  padding: 13px 10px !important;
  box-shadow: none !important;
  border: 1.5px solid ${(props) => (props.hasError ? "red" : "#bdc4d5")} !important;
  border-radius: 10px !important;
  font-size: 1em !important;
  width: 100% !important;
  height: auto !important;
`;

const SubmitButton = styled.button`
  align-self: center;
  color: #fff;
  border-color: rgba(0, 0, 0, 0.05);
  background-color: #84daea;
  border-radius: 12px;
  padding: 0 1.2em;
  line-height: 2.4em;
  min-height: 2.5em;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    animation: ${rotateSpinner} 0.8s ease infinite;
  }
`;

const _loadAddressSuggestions = (inputValue, callback) => {
  if (!inputValue || inputValue.length < 3) {
    callback([]);
    return;
  }
  axios.get(`${process.env.REACT_APP_API_URL}properties/search?term=${inputValue}`).then(({ data }) => {
    console.log(data);
    callback(data.results);
  });
};

const loadAddressSuggestions = debounce(_loadAddressSuggestions, 500);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    textAlign: "left",
    border: "1.5px solid #bdc4d5",
    borderRadius: 10,
    boxShadow: "none",
  }),
};

const reasonOptions = [
  { value: "Selling soon", label: "Selling soon" },
  { value: "Just curious", label: "Just curious" },
  { value: "Other", label: "Other" },
];

const App = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      property: null
    },
  });
  const { data, isLoading, refetch } = useQuery(
    "sendEnquiry",
    async () => {
      try {
        const formFields = getValues();
        // it's not checked by hook form
        console.log(formFields);
        if (!formFields.property && !formFields.property.value) return;

        const recaptcha = await executeRecaptcha();
        const { data } = await axios.post(
          `${process.env.REACT_APP_LEAD_API_URL}enquiries/`,
          {
            name: formFields.name,
            email: formFields.email,
            phone: formFields.phone,
            message: formFields.message?.value,
            propertyId: formFields.property.value,
            address: formFields.property.label
          },
          {
            headers: {
              recaptcha,
            },
          }
        );

        if (formFields.property.value) {
          window.open(`https://homes.disrupted.co.nz/${formFields.property.value}`);
        }
        return data;
      } catch (e) {
        console.error(e);
        const apiErrors = e?.response?.data?.message;
        return (Array.isArray(apiErrors) ? apiErrors : [apiErrors]) ?? [e.message];
      }
    },
    { enabled: false }
  );

  const onSubmit = async () => {
    refetch();
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} isHidden={data?.success}>
        {data?.length > 0 && (
          <ul>
            {data.map((message) => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        )}
        <Title>Find out your homes value.</Title>
        <Input {...register("name", { required: true })} placeholder="Name*" hasError={errors.name} />
        <Input {...register("email", { required: true })} placeholder="Email*" type="email" hasError={errors.email} />
        <Input {...register("phone", { required: true })} type="number" placeholder="Phone*" hasError={errors.phone} />
        <AsyncSelect
          cacheOptions
          onChange={(option) => setValue("property", option)}
          loadOptions={loadAddressSuggestions}
          placeholder="Property location"
          value={watch("property")}
          defaultOptions
          hasError={errors.property}
          styles={customStyles}
        />
        {/* <Input {...register("property", { required: true })} type="text" placeholder="Property location" hasError={errors.property} /> */}

        <Select
          {...register("message", { required: true })}
          onChange={(option) => setValue("message", option)}
          placeholder="Reason for enquiry*"
          isClearable={true}
          options={reasonOptions}
          value={watch("message")}
          defaultOptions
          hasError={errors.message}
          styles={customStyles}
          required={true}
        />
        {/* <Input {...register("message", { required: true })} placeholder="Message*" hasError={errors.message} /> */}
        <SubmitButton disabled={isLoading}>
          {isLoading ? (
            <>
              <ImSpinner9 />
              <span>Loading</span>
            </>
          ) : (
            "Submit"
          )}
        </SubmitButton>
      </Form>
      {data?.success && (
        <ConformationMessage>Thanks for contacting us! We will get in touch with you shortly.</ConformationMessage>
      )}
    </Container>
  );
};

export default App;
